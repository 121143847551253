import {
  getActiveAccessAuthsByContext,
  getMyActiveAccessRequests
} from '@/store/modules/requestsApi/fbReadRequestsApi'

export function listen2DataTable(type, rs, ou, area, roles, uid, store) {
  return type === 'request'
    ? getMyActiveAccessRequests(rs, ou, uid, store)
    : type === 'auth'
    ? getActiveAccessAuthsByContext(rs, ou, area, roles, store)
    : () => {}
}
