export function isAccessRequest(type) {
  return type === 'request' || type === 'auth'
}

export function isDocRequirement(type) {
  return type === 'auth_doc'
}

export function isRequestRoleActivity(type) {
  return type === 'request'
}

export function isAuthRoleActivity(type) {
  return type === 'auth'
}

export function isRequestDocRoleActivity(type) {
  return type === 'request_doc'
}

export function isAuthDocRoleActivity(type) {
  return type === 'auth_doc'
}

export function isSomeDocRequirementActivity(type) {
  return isRequestDocRoleActivity(type) || isAuthDocRoleActivity(type)
}

export function isSomeAuthActivity(type) {
  return isAuthRoleActivity(type) || isAuthDocRoleActivity(type)
}
