export function semaphoreColorByQty(qty) {
  const qtyInt = parseInt(qty)
  return qtyInt < 10
    ? 'light-green darken-1'
    : qtyInt < 20
    ? 'orange darken-2'
    : 'red accent-3'
}

export function semaphoreColorByTimeQty(qty) {
  const qtyInt = parseInt(qty)
  return qtyInt < 50
    ? 'light-green darken-1'
    : qtyInt < 100
    ? 'orange darken-2'
    : 'red accent-3'
}
