const semaphoreColor = completed => {
  let color = 'red'
  if (completed > 60 && completed < 80) {
    color = 'amber'
  } else if (completed >= 80) {
    color = 'green'
  }
  return color
}
export default semaphoreColor
